import React, { useEffect } from 'react';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import DocumentDraw from '../components/DocumentDraw';
import { buildHash, getImage } from '../apis/imagem';
import { ImageModel } from '../models/imageModel';
import { useSearchContext } from '../context/SearchContext';
import { useSearchTextContext } from '../context/SearchTextContext';
import { useMessageBoxContext } from '../context/MessageBoxContext';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from '@progress/kendo-react-indicators';
import { useLoadingContext } from '../context/LoadingContext';
import { FoldersModel } from '../models/foldersModel';
import { isMobile } from 'react-device-detect';
import { searchApi } from '../apis/search';
import FolderEmpty from "../assets/FolderEmpty.svg"
import { getDefaultBiblioteca } from '../services/appSettings';
import { exibirMensagem } from '../services/languages/languages';
import { AppBar, AppBarSection, AppBarSpacer, Drawer, DrawerContent, GridLayout, GridLayoutItem, Splitter, SplitterPane } from '@progress/kendo-react-layout';
import DocTypeComponent from '../components/DocType';
import ThumbsGrid from '../components/Thumbs/ThumbsGrid';
import { useLibraryContext } from '../context/LibraryContext';
import FoldersControls from '../components/FoldersControls';
import SharedSocialButtons from '../components/SharedSocialButtons';
import { ExportPdfControl } from '../components/ExportPdf';
import FoldersTreeView from '../components/FoldersTreeView';
import LibraryDetails from '../components/LibraryDetails';
import ThumbsMenu from '../components/Thumbs/ThumbsMenu';
import { Header } from '../components/DocReader/Header';
import { folderIcon, infoCircleIcon, searchIcon, downloadIcon, thumbnailsDownIcon } from '@progress/kendo-svg-icons';
import { useControlContext } from '../context/ControlContext';
import Footer from '../components/DocReader/Footer';

interface DocReaderProps {
  library: string;
}

export const DocReader: React.FC<DocReaderProps> = ({ library }) => {
  const [image, setImage] = React.useState<ImageModel | null>(null);
  const [cachedImages, setCachedImages] = React.useState<ImageModel[]>([]);
  const { Folder } = useFolderContext();
  const { Page } = usePageContext();
  const { PageLoad, setPageLoad } = usePageLoadContext();
  const { setSearch } = useSearchContext();
  const { SearchText } = useSearchTextContext();
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { Loading, setLoading } = useLoadingContext();
  const { Library } = useLibraryContext();
  const [expanded] = React.useState(false);
  const [ isSearch, setIsSearch ] = React.useState(false);
  const [ isFoldersVisible, setIsFoldersVisible ] = React.useState(false);
  const [ isPdfVisible, setIsPdfVisible ] = React.useState(false);
  const [ isLibraryDetailsVisible, setIsLibraryDetailsVisible ] = React.useState(false);
  const [ isThumbsMenuVisible, setIsThumbsMenuVisible] = React.useState<boolean>(false);
  const [ isThumbsVisible, setIsThumbsVisible] = React.useState<boolean>(false);
  const { commandControlContext, setCommandControlContext } = useControlContext();
  const [ isSearchBoxVisible, setIsSearchBoxVisible ] = React.useState<boolean>(false);

  // Hook para atualizar a Pagina na tela
  useEffect(() => {
    // Carrega a imagem
    const fetchImage = async (folder: FoldersModel, page: number): Promise<boolean> => {
      try {
        if (folder?.Id === 0 || page === 0) return false;

        setLoading(true);

        const hashCurrent = buildHash(SearchText ?? '', library ?? (getDefaultBiblioteca() ?? ''), folder?.Id, page);

        //// Pega a imagem do cache, quando já acessou antes
        const cachedImage = cachedImages.find(h => h.hash === hashCurrent);
        if (cachedImage) {
          setImage(cachedImage);
          setLoading(false);
          return false;
        }

        // Pega a imagem do servidor
        const resultImage = await getImage(hashCurrent, library ?? (getDefaultBiblioteca() ?? ''), folder?.Id, page);

        setLoading(false);

        if (resultImage === null) return false;

        if (!resultImage.sucesso) {
          setImage(null);
          setMessageBox(resultImage.mensagem ?? 'Falha geral');
          return false;
        }

        if (resultImage.hash === undefined) return false;

        setCachedImages(cachedImages => [...cachedImages, resultImage]);

        setImage(resultImage);

        return true;
      }
      catch (error) {
        setLoading(false);
        console.error('Error fetching image:', error);
        return false;
      }
    }

    if (!PageLoad) return;

    setTimeout(() => {
      setPageLoad(false);
    }, 100);

    // Pasta sem páginas
    if (Folder === null || Page === null || Page === 0) {
      setImage({ base64: FolderEmpty } as ImageModel);
      return;
    }

    fetchImage(Folder, Page);
  }, [PageLoad]);

  // Hook para fazer a pesquisa
  useEffect(() => {
    const search = async (): Promise<boolean> => {
      try {
        if (SearchText === '' || SearchText === null || SearchText === undefined) {
          window.history.replaceState(null, "", "/" + library);
          return false;
        }

        setLoading(true);
        let searchTmp = await searchApi(library ?? '', SearchText);
        setLoading(false);

        if (searchTmp.status === 'ok') {
          if (searchTmp.pagesCount > 0) {
            searchTmp.matchs.sort((a, b) => a.folderID - b.folderID || a.page - b.page); //Ordena as ocorrencias por folder/page
            setSearch(searchTmp);
            window.history.replaceState(null, "", "/" + library + "/search/" + SearchText);
          }
          else {
            setSearch(null);
            setMessageBox(exibirMensagem("semOcorrencias"));
            window.history.replaceState(null, "", "/" + library);
          }
        }
        else {
          setSearch(null);
          setMessageBox(exibirMensagem("semOcorrencias"));
          window.history.replaceState(null, "", "/" + library);
        }
      } catch (error) {
        setSearch(null);
        setMessageBox("Erro na pesquisa");
      }

      return true;
    }

    search()
  }, [SearchText]);

  useEffect(() => {
    if (Library != null) {
      setIsThumbsVisible(Library?.thumbs > 0 ? true : false);
    }     
  }, [Library?.thumbs]);

  const closeMessageBox = () => {
    setMessageBox(null);
  };

  const items = [
    {
      text: exibirMensagem('pastas'),
      svgIcon: folderIcon,
      selected: true,
    },
    {
      text: exibirMensagem("zoom"),
      svgIcon: searchIcon,
    },
    {
      text: exibirMensagem("download") + ' Pdf',
      svgIcon: downloadIcon,
    },
    {
      text: exibirMensagem("info"),
      svgIcon: infoCircleIcon
    },    
    {
      text: "thumbs",
      svgIcon: thumbnailsDownIcon
    },        
  ];

  const onSelect = (e: any) => {
    if (e.itemTarget.props.text === exibirMensagem('pastas')) {
      setIsFoldersVisible(!isFoldersVisible);
      setIsPdfVisible(false);
      setIsLibraryDetailsVisible(false);
    }
    else if (e.itemTarget.props.text ===  exibirMensagem("zoom")) {
      setCommandControlContext({ isZoomVisible: !commandControlContext?.isZoomVisible });
    }
    else if (e.itemTarget.props.text === (exibirMensagem("download") + ' Pdf')) {
      setIsFoldersVisible(false);
      setIsPdfVisible(!isPdfVisible);
      setIsLibraryDetailsVisible(false);
    }
    else if (e.itemTarget.props.text === exibirMensagem("info")) {
      setIsFoldersVisible(false);
      setIsPdfVisible(false);
      setIsLibraryDetailsVisible(!isLibraryDetailsVisible);
    }
    else if (e.itemTarget.props.text === "thumbs") {      
      setIsThumbsMenuVisible(!isThumbsMenuVisible);
    }
  };

  return (
    <>
      <FoldersControls library={library} />

      {isMobile ?
        <>
          {Loading ?
            <span className='loader-mobile'>
              <Loader type='converging-spinner' />
            </span>
            : <></>
          }

          <Header isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible}/>

          <div id='docpro' className='maindiv'>
            <DocumentDraw image={image ?? undefined} />
          </div>

          {Library?.docType ?? 0 > 0 ? <DocTypeComponent library={library} folder={Folder?.Id ?? 0} page={Page ?? 0} /> : <></>}

          <Footer isFoldersVisible={isFoldersVisible} setIsFoldersVisible={setIsFoldersVisible} isThumbsVisible={isThumbsVisible} setIsThumbsVisible={setIsThumbsVisible}
                  isThumbsMenuVisible={isThumbsMenuVisible} setIsThumbsMenuVisible={setIsThumbsMenuVisible} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible} />

          <ThumbsMenu isVisible={isThumbsMenuVisible} setIsVisible={setIsThumbsMenuVisible} />

          <FoldersTreeView isVisible={isFoldersVisible} setIsVisible={setIsFoldersVisible}/>

          <ThumbsGrid isVisible={isThumbsVisible} setIsVisible={setIsThumbsVisible} library={library} />

          {messageBox !== null && (
            <Dialog onClose={closeMessageBox} width={300} height={400} closeIcon={true} title={"Aviso"} themeColor='primary' >
              <div className='messageboxdiv' >
                {messageBox}
              </div>
            </Dialog>
          )}
        </>
        :
        <>
          {Loading ?
            <span className='loader'>
              <Loader type='converging-spinner' />
            </span>
            : <></>
          }

          <Header isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible}/>

          <Drawer expanded={expanded} position={'start'} mode={'push'} mini={true} items={items.map((item) => ({...item,selected: false}))} onSelect={onSelect}>          
            <DrawerContent>
              <FoldersTreeView isVisible={isFoldersVisible} setIsVisible={setIsFoldersVisible} />

              {isLibraryDetailsVisible ? <LibraryDetails isVisible={isLibraryDetailsVisible} setIsVisible={setIsLibraryDetailsVisible} /> : null}

              <ExportPdfControl library={library} isVisible={isPdfVisible} setIsVisible={setIsPdfVisible} />

              <ThumbsMenu isVisible={isThumbsMenuVisible} setIsVisible={setIsThumbsMenuVisible} />

              <div id='docpro' className='maindiv'>
                <DocumentDraw image={image ?? undefined} />
              </div>

              {Library?.docType ?? 0 > 0 ? <DocTypeComponent library={library} folder={Folder?.Id ?? 0} page={Page ?? 0} /> : <></>}
            </DrawerContent>
          </Drawer>

          <ThumbsGrid isVisible={isThumbsVisible} setIsVisible={setIsThumbsVisible} library={library} />

          <SharedSocialButtons />

          {messageBox !== null && (
            <Dialog onClose={closeMessageBox} width={450} height={200} closeIcon={true} title={"Aviso"} themeColor='primary' >
              <div className='messageboxdiv'>
                {messageBox}
              </div>
            </Dialog>
          )}
        </>
      }
    </>
  );
} 