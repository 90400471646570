import axios from 'axios';
import { getApiUrlMetadata, getShowLogs } from '../services/appSettings';
import { DocType, ResponseDocType } from '../models/docType';

const baseUrl = getApiUrlMetadata();

export const docTypeApi = async (library: string, folder: number, page: number): Promise<DocType | null> => {
  
    if (!baseUrl) {
        console.error('Environment variable REACT_APP_API_URL_METADATA is not set');
        return null;
    }

    const url = `${baseUrl}/${library}/${folder}/${page}`;

    if (getShowLogs() === 'on') {
        console.log('Download DocType API:: url: ' + url);
    }

    try {
        const response = await axios.get(url);
        const responseData = response.data as ResponseDocType;
        return responseData.data;
    } catch (error) {
        console.error('API call failed for library:', library, 'folder:', folder, page, error);
        return null;
    }
};