import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import { BaseModel } from '../models/baseModel';
import { LibraryModel, LibraryBaseModel } from '../models/librariesModel';
import { FoldersModel } from '../models/foldersModel';
import { FoldersListModel } from '../models/foldersListModel';
import { filterIcon, xIcon } from '@progress/kendo-svg-icons'; 
import { SvgIcon } from '@progress/kendo-react-common'; 
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { useParams } from 'react-router-dom';
import { mapFoldersModel } from '../services/shared';
import { Loader } from '@progress/kendo-react-indicators';
import { getApiUrlFolders, getApiUrlLibrary, getDefaultBiblioteca } from '../services/appSettings';
import { exibirMensagem } from '../services/languages/languages';
import { usePageLoadContext } from '../context/PageLoadContext';

interface LibraryDetailsProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LibraryDetails: React.FC<LibraryDetailsProps> = ({ isVisible, setIsVisible }) => {
  const { library } = useParams();
  const [ filterValue, setFilterValue ] = React.useState();  
  const [ LibraryModel, setLibraryModel ] = useState<LibraryModel | null>(null);
  const [ foldersModel, setFoldersModel ] = React.useState<FoldersModel[]>([]);
  const [ data, setData ] = useState<FoldersListModel[]>([]);
  const [ iniciado, setIniciado ] = useState<boolean>(false);
  const [ dataResult, setDataResult ] = useState<FoldersListModel[]>([]);
  const { setFolder } = useFolderContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();

  const onFilterChange = (ev: any) => {
    let value = ev.value; 
    setFilterValue(ev.value);
    let newData = data.filter((item) => {
      let match = false;      
        if (
          item.Name
            .toString()
            .toLocaleLowerCase()
            .indexOf(value.toLocaleLowerCase()) >= 0
        ) {
          match = true;
        }              
      return match;
    });

    setDataResult(newData);
  };

  const onRowClick = (e: any) => {
    const item : FoldersModel = e.dataItem;
    if (item==null) return;
    
    setFolder(item);
    setPage(1);

    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  };

  useEffect(() => {
    const baseUrl = getApiUrlLibrary();
    if (!baseUrl) {
      console.error('Environment variable REACT_APP_API_URL_LIBRARY is not set');
      return;
    }
   
    var url = `${baseUrl}/${(library ?? (getDefaultBiblioteca() ?? ''))}`;
    axios.get(url)
      .then(response => {                  
        var rowData = response.data as LibraryBaseModel;       
       
        setLibraryModel(rowData.data ?? null);
      })
      .catch(error => {
          console.error(error);              
        }          
        );
  }, [iniciado]);   

  useEffect(() => {
    const baseUrl = getApiUrlFolders();
    if (!baseUrl) {
      console.error('Environment variable REACT_APP_API_URLFOLDERS is not set');
      return;
    }
   
    var url = `${baseUrl}/${(library ?? (getDefaultBiblioteca() ?? ''))}`;

    axios.get(url)
      .then(response => {                  
        var rowData = response.data as BaseModel;          
        var dados = JSON.parse(rowData.data) as FoldersModel[]; 
        setFoldersModel(dados);
      })
      .catch(error =>          
        { 
          console.error(error);                      
        }          
      );
  }, [LibraryModel]);

  useEffect(() => {
    let dataLista: FoldersListModel[] = [];    
    mapFoldersModel(foldersModel, '', dataLista);
    setDataResult(dataLista);
    setData(dataLista);
    setIsVisible(true);
  }, [foldersModel]);  
 
  if (!iniciado) {
    const PTimeout2start = 500;
    setTimeout(() => {
      setIniciado(true);
    }, PTimeout2start);
  }
 
  return (
    <div>
      {isVisible  ?
        
      <div className={'leftpanel'}>
        {!iniciado ? 
          <span className='loader'>
            <Loader type='converging-spinner' />                    
          </span>
        : <></>
        }
          <div className='leftpanelHeader'>                  
            <div className='leftpanelTitle'>{exibirMensagem("informacoes")}</div>   
            <div onClick={()=> setIsVisible(false)} className='leftpanelClose'>
                <span><SvgIcon className='closeSvgIcon' icon={xIcon} /></span>  
            </div>       
            <div className='leftpanelFilter' >
              <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon}/> </span>
              <Input className='leftpanelFilterInput'
                value={filterValue}
                onChange={onFilterChange}
                placeholder={exibirMensagem("filtrar") + '...'}
              /> 
            </div>
          </div>                  

          <div className='libraryDetail'>
            <span className='topdiv'>
              <span className='labelText'>{exibirMensagem("alias")}:&nbsp;{LibraryModel?.name ?? '' }</span>
              <span className='labelText'>{exibirMensagem("nome")}:&nbsp;{LibraryModel?.description ?? '' }</span>
            </span>
            
            <Grid className='gridFolders' data={dataResult} sortable={true} size={'small'} onRowClick={onRowClick}>          
              <Column className='smallCount' field='Counter' title='#' width='35px' />     
              <Column field='Name' title='Pasta' width='320px' />
              <Column className='textright' field='PagesTotal' title={exibirMensagem("paginas")} width='70px' />
            </Grid>      
        
            <span className='bottomdiv'>
              <span className='left'>{exibirMensagem("totalDePastas")}: {dataResult.length}</span>
              <span className='right'>{exibirMensagem("totalDePaginas")}: {dataResult.reduce((sum, item) => sum + (item.PagesTotal || 0), 0)}</span>
            </span>
        </div>
      </div> 
      : <></>}
    </div>
  )};

export default LibraryDetails;