import axios from 'axios';
import { LibraryBaseModel } from '../models/librariesModel';
import { getApiUrlLibrary, getShowLogs } from '../services/appSettings';

const baseUrl = getApiUrlLibrary();

export const libraryApi = async (
  library: string
): Promise<LibraryBaseModel> => {


  const url = new URL(`${baseUrl}/${library}`);

  var result: Promise<LibraryBaseModel> = axios.get(url.toString())
    .then(response => {
      var rowData = response.data as LibraryBaseModel;
      if (rowData.sucesso) {
        return rowData;
      }
      return { mensagem: rowData.mensagem, data: null } as LibraryBaseModel;
    })
    .catch(error => {
      if (getShowLogs() === 'on') {
        console.error(error);
      }

      if (error.response && error.response.status === 422) {
        return { mensagem: '-422', data: null } as LibraryBaseModel;
      }
      else if (error.response && error.response.status === 404) {
        return { mensagem: '-404', data: null } as LibraryBaseModel;
      }

      return { mensagem: '-1', data: null } as LibraryBaseModel;
    }
    );
  return result;
};