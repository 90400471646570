export const ptMessages = {
  doctypetitle: "Metadados",
  ocorrencias: "Ocorrências",
  semOcorrencias: "Sem ocorrências",
  todasOcorrenciasPastaAtual: "Ocorrências da pasta atual",
  todasOcorrencias: "Todas as ocorrências",
  todaAPastaAtual: "Toda a pasta atual",
  incluirSubpastas: "Incluir subpastas",
  pastas: "Pastas",
  paginas: "Páginas",	
  pagina: "Página",
  totalDePastas: "Total de pastas",
  totalDePaginas: "Total de páginas",
  incluirSubPastas: "Incluir subpastas",
  alias: "Alias",
  nome: "Nome",
  informacoes: "Informações",
  paginasDe: "Páginas de",
  ate: "até",
  qualidade: "Qualidade",
  gerar: "Gerar",
  naoExistePaginas: "Não existem páginas para gerar um PDF",
  download: "Download",
  downloads: "Downloads",
  info: "Info",
  zoom: "Zoom",
  digiteTextoParaPesquisar: "Digite um texto para pesquisar...",
  pressioneEnterParaPesquisar: "Pressione ENTER para pesquisar",
  limpaaPesquisa: "Limpa a pesquisa",
  pesquisarPontos: "Pesquisar...",
  filtrar: "Filtrar",
  filtrarNomesDeBibliotecas: "Filtrar nomes de bibliotecas...",
  cliqueParaPesquisar: "Clique para pesquisar",
  compartilharNo: "Compartilhar no",
  descricao: "Descrição",
  pesquisarNasBibliotecas: "Pesquisar nas bibliotecas...",
  acervos: "Acervos",
  exibir: "Exibir",
  ocultar: "Ocultar",
  capas: "Capas",
  miniaturas: "Miniaturas",
  miniaturasOcorrencias: "Miniaturas das ocorrências",
  miniaturasCapas: "Miniaturas das capas",
  fechar: "Fechar",
  fecharMenu: "Fechar menu",
  miniaturasMarcadas: "Miniaturas marcadas",
};


export const ptMessagesDebug = {
    ocorrencias: "XXOcorrências",
    semOcorrencias: "XXSem ocorrências",
    todasOcorrenciasPastaAtual: "XXOcorrências da pasta atual",
    todasOcorrencias: "XXTodas as ocorrências",
    todaAPastaAtual: "XXToda a pasta atual",
    incluirSubpastas: "XXIncluir subpastas",
    pastas: "XXPastas",
    paginas: "XXPáginas",	
    pagina: "XXPágina",
    totalDePastas: "XXTotal de pastas",
    totalDePaginas: "XXTotal de páginas",
    incluirSubPastas: "XXIncluir subpastas",
    alias: "XXAlias",
    nome: "XXNome",
    informacoes: "XXInformações",
    paginasDe: "XXPáginas de",
    ate: "XXaté",
    qualidade: "XXQualidade",
    gerar: "XXGerar",
    naoExistePaginas: "XXNão existem páginas para gerar um PDF",
    download: "XXDownload",
    downloads: "XXDownloads",
    info: "XXInfo",
    zoom: "XXZoom",
    digiteTextoParaPesquisar: "XXDigite um texto para pesquisar...",
    pressioneEnterParaPesquisar: "XXPressione ENTER para pesquisar",
    limpaaPesquisa: "XXLimpa a pesquisa",
    pesquisarPontos: "XXPesquisar...",
    filtrar: "XXFiltrar",
    filtrarNomesDeBibliotecas: "XXFiltrar nomes de bibliotecas...",
    cliqueParaPesquisar: "XXClique para pesquisar",
    compartilharNo: "XXCompartilhar no",
    descricao: "XXDescrição",
    pesquisarNasBibliotecas: "XXPesquisar nas bibliotecas...",
    acervos: "XXAcervos",
    exibir: "XXExibir",
    ocultar: "XXOcultar",
    capas: "XXCapas",
    miniaturas: "XXMiniaturas",
    miniaturasOcorrencias: "XXMiniaturas das ocorrências",
    miniaturasCapas: "XXMiniaturas das capas",
    fechar: "XXFechar",
    fecharMenu: "XXFechar menu",
    miniaturasMarcadas: "XXMiniaturas marcadas",
};