import React, { useCallback, useEffect, useState } from 'react';
import { getInitialHeightThumbs } from '../../services/appSettings';
import { ImageModel } from '../../models/imageModel';
import Thumbs from './Thumbs';
import { Window } from "@progress/kendo-react-dialogs";
import { ListView } from "@progress/kendo-react-listview";
import { useFolderContext } from '../../context/FolderContext';
import { exibirMensagem } from '../../services/languages/languages';
import { useSearchContext } from '../../context/SearchContext';
import { useFoldersContext } from '../../context/FoldersContext';
import { FoldersModel } from '../../models/foldersModel';
import { thumbsModel } from '../../models/thumbsModel';
import { useLibraryContext } from '../../context/LibraryContext';
import { useThumbsWindowRectContext } from '../../context/ThumbsWindowRectContext';
import { Loader } from '@progress/kendo-react-indicators';
import { isMobile } from 'react-device-detect';
import { Button } from '@progress/kendo-react-buttons';
import { useThumbsLoadContext } from '../../context/ThumbsLoadContext';

interface ThumbsGridProp {
    library: string;
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;  
}

const ThumbsGrid: React.FC<ThumbsGridProp> = ({ library, isVisible, setIsVisible }) => {
    const itensPerRowIni = 8;
    const PDefaultCalcWidth = 8;
    const PDefaultCalcHeight = 0.3;
    const [listviewHeight, setListviewHeight] = useState<number>(1000);
    const [listviewMaxHeight, setListviewMaxHeight] = useState<number>(200);
    const [loading, setLoading] = useState<boolean>(true);
    const [initialMinThumbs, setInitialMinThumbs] = useState<number>(itensPerRowIni);
    const [cachedImages, setCachedImages] = React.useState<ImageModel[]>([]);
    const [itensPerRow, setItensPerRow] = useState<number>(itensPerRowIni);
    const [itensTotal, setItensTotal] = useState<number>(0);
    const [thumbs, setThumbs] = useState<thumbsModel[] | null>(null);
    const [data, setData] = useState<thumbsModel[]>([]);
    const [heightThumbsConfig] = useState<number>(getInitialHeightThumbs());
    const [caption, setCaption] = useState<string>("");
    const { Search } = useSearchContext();
    const { Folder } = useFolderContext();
    const { Folders } = useFoldersContext();
    const { Library, setLibrary } = useLibraryContext();
    const { ThumbsWindowRect, setThumbsWindowRect } = useThumbsWindowRectContext();
    const { ThumbsLoad, setThumbsLoad } = useThumbsLoadContext();

    // Hook para definir o tamanho da windows, mesmo fechando guarda a posição e tamanho caso abra de novo
    useEffect(() => {
      if (ThumbsWindowRect?.top == null || ThumbsWindowRect?.left == null || ThumbsWindowRect?.width == null || ThumbsWindowRect?.height == null ) {
        setThumbsWindowRect({ left: window.innerWidth > 1200 ? (window.innerWidth - 1000)/2 : (window.innerWidth - window.innerWidth * .8)/2, 
                              top: window.innerHeight * .7, 
                              width: window.innerWidth > 1200 ? 1000 : window.innerWidth * .8, 
                              height: 220 });
      }

      setThumbsLoad(false);
    });

    const calculateThumbs = (width: number, height: number) => {
      const itemWidth = PDefaultCalcWidth + heightThumbsConfig;
      const itemHeight = (heightThumbsConfig * PDefaultCalcHeight) + heightThumbsConfig;
      let nItensPerRow = Math.floor(width / itemWidth);
      nItensPerRow--;
      if (nItensPerRow <= 0) nItensPerRow = 1;
      setItensPerRow(nItensPerRow);
      let nItensPerColumn = Math.floor(height / itemHeight);

      const calcItens = (nItensPerRow * nItensPerColumn);
      const result = calcItens - (calcItens > nItensPerRow ? nItensPerRow : 0);        
      return result;
    };

    const handleResize = (e: any) => {
      setThumbsWindowRect({ left: e.left, top: e.top, width: e.width, height: e.height });

      const newWidth = Number(e.width);
      const newHeight = Number(e.height);
      const newMinThumbs = calculateThumbs(newWidth, newHeight);
      setInitialMinThumbs(newMinThumbs);
      setItensTotal(newMinThumbs);
      
      const windowTitleHeight = 42; // Tamanho da barra de titulo da window
      setListviewHeight(newHeight - windowTitleHeight);
      setListviewMaxHeight(newHeight - windowTitleHeight);
    };

    // Hook para recarregar mais itens
    useEffect(() => {
      if (!thumbs) return;

      setLoading(true);

      setTimeout(() => {
        setData((preData) => {
          const newData = [...preData];

          for (let i = newData.length; i < itensTotal; i++) {
            if (thumbs[i]) {
              newData.push(thumbs[i]);
            }
          }
          return newData;
        });
      }, 500);

      setLoading(false);
    }, [itensTotal, thumbs]);

    const renderThumbs = (): thumbsModel[] => {
      const thumbs: thumbsModel[] = [];
      const nMax = Folder?.Pages === undefined ? 0 : Folder.Pages;

      for (let i = 1; i <= nMax; i++) {
        thumbs.push({
          folderId: Folder?.Id ?? 0,
          page: i
        });
      }

      setCaption(exibirMensagem("miniaturas"));
      return thumbs;
    };

    const renderThumbsOcorrencias = (): thumbsModel[] => {
      const thumbs: thumbsModel[] = [];
      const nMax = Search?.matchsCount === undefined ? 0 : Search.pagesCount;

      for (let i = 0; i < nMax; i++) {
        var match = Search?.matchs[i];

        if (match === undefined) {
          break;
        }

        thumbs.push({
          folderId: match.folderID,
          page: match.page
        });
      }

      setCaption(exibirMensagem("miniaturasOcorrencias"));
      return thumbs;
    };

    const renderThumbsCapas = (): thumbsModel[] => {
      if (Folders === undefined || Folders === null) return [];

      const renderSubFolder = (folderX: FoldersModel): thumbsModel[] => {
        const thumbs: thumbsModel[] = [];
        if (folderX.Pages != 0) {
          thumbs.push({
            folderId: folderX.Id,
            page: 1,
          }
          );
        }

        folderX.SubFolders?.forEach(subFolder => {
          thumbs.push(...renderSubFolder(subFolder));
          if (thumbs.length >= initialMinThumbs) {
            return thumbs;
          }
        });

        setCaption(exibirMensagem("miniaturasCapas"));
        return thumbs;
      };

      const renderSubFolders = (folderX: FoldersModel[], nCount: number): thumbsModel[] => {
        const thumbs: thumbsModel[] = [];
        folderX.forEach(folder => {
          thumbs.push(...renderSubFolder(folder));
          return thumbs;
        });
        return thumbs;
      }

      return renderSubFolders(Folders, 0);
    };

    const closeThumbs = () => {
      if (Library != null) Library.thumbs = 0;
      setLibrary(Library);
      setIsVisible(false);
    }; 

    // Hook para mudança do tipo de Thumbs
    useEffect(() => {
      console.debug(Library?.thumbs);
      if (Library === null || Library?.thumbs <= 0) return;

      setData([]);

      setTimeout(() => {
        if (Library?.thumbs === 1) setThumbs(renderThumbs());
        else if (Library?.thumbs === 2) setThumbs(renderThumbsOcorrencias());
        else if (Library?.thumbs === 3) setThumbs(renderThumbsCapas()); 
      }, 500);
    }, [ThumbsLoad]);

    useEffect(() => {
      if (!thumbs) return;
      setItensTotal((prevSI) => { return prevSI === 0 ? initialMinThumbs + 1 : prevSI });
    }, [thumbs]);

    const RenderMiniaturas = useCallback(({ dataItem }: { dataItem: thumbsModel }) => { 
      return (
        <Thumbs
          height={heightThumbsConfig}
          key={`${Library?.thumbs}-${dataItem.folderId}`}
          library={library}
          folderId={dataItem.folderId}
          page={dataItem.page}
          cachedImages={cachedImages}
          setCachedImages={setCachedImages}
        />
      );
    }, []);

    const scrollHandler = (event: any) => {
      const e = event.nativeEvent;
      if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
        setItensTotal((prevSI) => prevSI + itensPerRow);
      }
    };   

    const MoreItens = () => {
      setItensTotal((prevSI) => prevSI + itensPerRow);
    };

    return (
      <>
        {isVisible ? 
          <>
            {isMobile ?
              <Window className='ThumbsWindows' title={caption} onResize={handleResize} onClose={closeThumbs} initialTop={ThumbsWindowRect?.top} initialLeft={ThumbsWindowRect?.left}
                      initialHeight={ThumbsWindowRect?.height} initialWidth={ThumbsWindowRect?.width} minimizeButton={() => null} maximizeButton={() => null} stage="FULLSCREEN" >

                {loading ?
                  <span className='loader-thumbs'>
                    <Loader type='converging-spinner' />
                  </span>
                : 
                <></>
                }

                <ListView className='ThumbsListview' onScroll={scrollHandler} data={data} item={RenderMiniaturas} />

                <style>
                  {`.k-listview-content {
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      height: ${listviewMaxHeight}px;
                      max-height: ${listviewMaxHeight}px;
                  }`}

                  {`.k-listview-item {
                    margin: 10px;
                  }`}

                  {`.k-listview-item, .thumbItem {
                    display: block;
                    float: left;
                    width: ${heightThumbsConfig}px;
                    max-width: ${heightThumbsConfig}px;
                    min-width: ${heightThumbsConfig}px; 
                    max-height: ${heightThumbsConfig + 46}px;
                    min-height:  ${heightThumbsConfig + 46}px;
                  }`}

                  {`.imgThumbDrawContainer,
                    .imgThumbDraw {
                    width: ${heightThumbsConfig - 10}px;
                    max-width: ${heightThumbsConfig - 10}px;
                    min-width: ${heightThumbsConfig - 10}px;
                    max-height: ${heightThumbsConfig + 15}px;
                  }`}

                  {`.imgThumbDrawContainer {
                    min-height:  ${heightThumbsConfig + 15}px;
                  }`}
                </style>
              </Window>
            :
              <Window className='ThumbsWindows' title={caption} onResize={handleResize} onClose={closeThumbs} initialTop={ThumbsWindowRect?.top} initialLeft={ThumbsWindowRect?.left}
                      initialHeight={ThumbsWindowRect?.height} initialWidth={ThumbsWindowRect?.width} minimizeButton={() => null} maximizeButton={() => null}>

                {loading ?
                  <span className='loader-thumbs'>
                    <Loader type='converging-spinner' />
                  </span>
                : 
                <></>
                }

                <ListView className='ThumbsListview' onScroll={scrollHandler} data={data} item={RenderMiniaturas} />

                {/* <Button title="carrega mais" onClick={MoreItens}>carrega mais</Button> */}

                <style>
                  {`.k-listview-content {
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      height: ${listviewHeight}px;
                      max-height: ${listviewMaxHeight}px;
                  }`}

                  {`.k-listview-item {
                    margin: 10px;
                  }`}

                  {`.k-listview-item, .thumbItem {
                    display: block;
                    float: left;
                    width: ${heightThumbsConfig}px;
                    max-width: ${heightThumbsConfig}px;
                    min-width: ${heightThumbsConfig}px; 
                    max-height: ${heightThumbsConfig + 46}px;
                    min-height:  ${heightThumbsConfig + 46}px;
                  }`}

                  {`.imgThumbDrawContainer,
                    .imgThumbDraw {
                    width: ${heightThumbsConfig - 10}px;
                    max-width: ${heightThumbsConfig - 10}px;
                    min-width: ${heightThumbsConfig - 10}px;
                    max-height: ${heightThumbsConfig + 15}px;
                  }`}

                  {`.imgThumbDrawContainer {
                    min-height:  ${heightThumbsConfig + 15}px;
                  }`}
                </style>
              </Window>
            }
          </>
        :
          <></>
        }
      </>
    );
};

export default ThumbsGrid;