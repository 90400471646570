import axios from 'axios';
import { FoldersModel } from '../models/foldersModel';  
import { BaseModel } from '../models/baseModel';  
import { getApiUrlFolders, getDefaultBiblioteca, getShowLogs } from '../services/appSettings';

const baseUrl = getApiUrlFolders();

export const foldersApi = async (library: string) : Promise<FoldersModel[]> => {

  if (!baseUrl) {
    console.error('Environment variable REACT_APP_API_URLFOLDERS is not set');
    return [{Id: 0, Name: '', Pages: 0, Parent: 0, Level: 0, HasSubFolders: false, SubFolders: null, Path: ''}];   
  } 

  var url = `${baseUrl}/${(library ?? (getDefaultBiblioteca() ?? ''))}`;  
  
  var result: Promise<FoldersModel[]> = axios.get(url)
    .then(response => {                  
        var rowData = response.data as BaseModel;                  
        if (rowData.sucesso) {
          var dados = JSON.parse(rowData.data) as FoldersModel[]; 
          return dados;
        }
        return [{Id: -4, Name: rowData.mensagem, Pages: 0, Parent: 0, Level: 0, HasSubFolders: false, SubFolders: null, Path: ''}];
    })
    .catch(error => {     
      if (getShowLogs() === 'on') {
        console.error(error);     
      }

      if (error.response && error.response.status === 422) {
        return [{Id: -422, Name: '', Pages: 0, Parent: 0, Level: 0, HasSubFolders: false, SubFolders: null, Path: ''}];
      }
      else if (error.response && error.response.status === 404) {
        return [{Id: -404, Name: '', Pages: 0, Parent: 0, Level: 0, HasSubFolders: false, SubFolders: null, Path: ''}];
      }

      return [{Id: -1, Name: '', Pages: 0, Parent: 0, Level: 0, HasSubFolders: false, SubFolders: null, Path: ''}];
    });
  return result;
};

