export const enMessages = {
    doctypetitle: "Metadata",
    ocorrencias: "Matchs",
    semOcorrencias: "No matchs",
    todasOcorrenciasPastaAtual: "Occurrences of the current folder",
    todasOcorrencias: "All occurrences",
    todaAPastaAtual: "All current folder",
    incluirSubpastas: "Include subfolders",
    pastas: "Folders",
    paginas: "Pages",
    pagina: "Page",
    totalDePastas: "Total folders",
    totalDePaginas: "Total pages",
    incluirSubPastas: "Include subfolders",
    alias: "Alias",
    nome: "Name",
    informacoes: "Informations",
    paginasDe: "Pages from",
    ate: "to",
    qualidade: "Quality",
    gerar: "Generate",
    naoExistePaginas: "There are no pages to generate a PDF",
    download: "Download",
    downloads: "Downloads",
    info: "Info",
    zoom: "Zoom",
    digiteTextoParaPesquisar: "Type a text to search...",
    pressioneEnterParaPesquisar: "Press ENTER to search",
    limpaaPesquisa: "Clear search",
    pesquisarPontos: "Search...",
    filtrar: "Filter",
    filtrarNomesDeBibliotecas: "Filter library names...",
    cliqueParaPesquisar: "Click to search",
    compartilharNo: "Share on",
    descricao: "Description",
    pesquisarNasBibliotecas: "Search in libraries...",
    acervos: "Libraries",
    exibir: "Show",
    ocultar: "Hide",
    capas: "Covers",
    miniaturas: "Thumbnails",
    miniaturasOcorrencias: "Thumbnails of occurrences",
    miniaturasCapas: "Thumbnails of covers",
    fechar: "Close",
    fecharMenu: "Close menu",
    miniaturasMarcadas: "Marked thumbnails",
};
