export const fetchSettingsSync = () => {

    const defaultResult = {
        "REACT_APP_SHOW_LOGS": "off",
        "REACT_APP_DEFAULT_BIBLIOTECA": "demo",
        "REACT_APP_API_URL_DOCUMENTS": "https://api.docvirt.com/v1/documents",
        "REACT_APP_API_URLFOLDERS": "https://api.docvirt.com/v1/folders",
        "REACT_APP_API_URL_SEARCH": "https://search.docvirt.com/v1/search",
        "REACT_APP_API_EXPORTPDF": "https://api.docvirt.com/v1/DocumentsExport/create",
        "REACT_APP_API_EXPORTPDF_STATUS": "https://api.docvirt.com/v1/DocumentsExport/status",
        "REACT_APP_API_EXPORTPDF_DOWNLOAD": "https://api.docvirt.com/v1/DocumentsExport/download",
        "REACT_APP_API_URL_LIBRARY": "https://api.docvirt.com/v1/Libraries",
        "REACT_APP_API_URL_METADATA": "https://api.docvirt.com/v1/Metadata",
        "REACT_APP_INITITAL_THUMB": "True",
        "REACT_APP_INITITAL_HEIGHT_THUMBS": "100"
    };

    try {
        const request = new XMLHttpRequest();
        request.open('GET', `${process.env.PUBLIC_URL}/${process.env.REACT_APP_API_FILE_CONFIG}`, false); // false makes the request synchronous
        request.send(null);

        if (request.status === 200) {
            return JSON.parse(request.responseText);
        } else {
            return defaultResult;
        }
    } catch (e) {
        return defaultResult;
    }
}

const settings = fetchSettingsSync();

export const getShowLogs = (): string => settings.REACT_APP_SHOW_LOGS;
export const getDefaultBiblioteca = (): string => settings.REACT_APP_DEFAULT_BIBLIOTECA;
export const getApiUrlDocuments = (): string => settings.REACT_APP_API_URL_DOCUMENTS;
export const getApiUrlFolders = (): string => settings.REACT_APP_API_URLFOLDERS;
export const getApiUrlSearch = (): string => settings.REACT_APP_API_URL_SEARCH;
export const getApiExportPdf = (): string => settings.REACT_APP_API_EXPORTPDF;
export const getApiExportPdfStatus = (): string => settings.REACT_APP_API_EXPORTPDF_STATUS;
export const getApiExportPdfDownload = (): string => settings.REACT_APP_API_EXPORTPDF_DOWNLOAD;
export const getApiUrlLibrary = (): string => settings.REACT_APP_API_URL_LIBRARY;
export const getApiUrlMetadata = (): string => settings.REACT_APP_API_URL_METADATA;
export const getInitialThumb = (): string => settings.REACT_APP_INITITAL_THUMB;
export const getInitialHeightThumbs = (): number => Number(settings.REACT_APP_INITITAL_HEIGHT_THUMBS);