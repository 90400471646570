import React, {} from 'react';
import { AppBar, AppBarSection } from '@progress/kendo-react-layout';
import { folderIcon, searchIcon, thumbnailsDownIcon } from '@progress/kendo-svg-icons';
import { isMobile } from 'react-device-detect';
import { Button } from '@progress/kendo-react-buttons';

interface FooterProps {
  isFoldersVisible: boolean;
  setIsFoldersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isThumbsVisible: boolean;
  setIsThumbsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isThumbsMenuVisible: boolean;
  setIsThumbsMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchBoxVisible: boolean;
  setIsSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer: React.FC<FooterProps> = ({ isFoldersVisible, setIsFoldersVisible, isSearchBoxVisible, setIsSearchBoxVisible, isThumbsMenuVisible, setIsThumbsMenuVisible }) => {
 
  return (
    <>
      {isMobile ?
        <>
          <AppBar position='bottom' positionMode='fixed'>
            <AppBarSection style={{width: '20%'}}>
            </AppBarSection>

            <AppBarSection style={{width: '20%'}}>
              <Button fillMode="flat" type="button" svgIcon={folderIcon} onClick={() => setIsFoldersVisible(!isFoldersVisible)} />
            </AppBarSection>

            <AppBarSection style={{width: '20%'}}>
              <Button fillMode="flat" type="button" svgIcon={thumbnailsDownIcon} onClick={() => setIsThumbsMenuVisible(!isThumbsMenuVisible)} />
            </AppBarSection>

            <AppBarSection style={{width: '20%'}}>
              <Button fillMode="flat" type="button" svgIcon={searchIcon} onClick={()=> setIsSearchBoxVisible(!isSearchBoxVisible)} />
            </AppBarSection>

            <AppBarSection style={{width: '20%'}}>
            </AppBarSection>
          </AppBar>
        </>
        :
        <>
        </>
      }
    </>
  );
};

export default Footer;