import { xIcon } from '@progress/kendo-svg-icons'; 
import { SvgIcon } from '@progress/kendo-react-common'; 
import { Button } from '@progress/kendo-react-buttons';
import React, { useEffect, useState } from 'react';  
import { Input } from '@progress/kendo-react-inputs'; 
import { useSearchTextContext } from '../../context/SearchTextContext';
import { useSearchContext } from '../../context/SearchContext';
import { isMobile } from 'react-device-detect';
import BackIcon from "../../assets/BackIcon.svg"
import SendIcon from "../../assets/SendIcon.svg"
import { usePageLoadContext } from '../../context/PageLoadContext';
import { exibirMensagem } from '../../services/languages/languages';

interface SearchBoxProps {
  isSearch: boolean;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchBoxVisible: boolean;
  setIsSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBox: React.FC<SearchBoxProps> = ({ isSearch, setIsSearch, isSearchBoxVisible, setIsSearchBoxVisible }) => {
  const { Search, setSearch } = useSearchContext();
  const { SearchText, setSearchText } = useSearchTextContext();
  const [ searchTextTmp, setSearchTextTmp ] = useState<string>(SearchText ?? '');
  const { setPageLoad } = usePageLoadContext();

  useEffect(() => {
    if (Search === null || Search === undefined) return;

    setIsSearchBoxVisible(false); // Mobile only
    setIsSearch(true);
    setSearchTextTmp(SearchText || '');
  }, [Search]);

  const SearchCmd = () => {
    setSearchText(searchTextTmp);
  }

  const clear = () => {
    setIsSearch(false);
    setSearchTextTmp('');
    setSearchText('');
    setSearch(null);
    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  };

  const handleKeyUp = (event: any) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      SearchCmd();
    }
  }

  return (
    <>
    {isMobile ? 
      isSearchBoxVisible ?
        <div className="searchbox-mobile">
          <div className='searchleft-mobile' onClick={() => setIsSearchBoxVisible(false)}>
            <img src={BackIcon} className='backicon-mobile' alt="back"/>
          </div>
          <div className='searchmiddle-mobile'>
            <Input placeholder={exibirMensagem("digiteTextoParaPesquisar")} title={exibirMensagem("pressioneEnterParaPesquisar")} className='searchinput-mobile' value={searchTextTmp} onKeyUp={handleKeyUp} 
                  onChange={(e) => {setSearchTextTmp(e.value);}} autoFocus={true}/>
          </div>
          <div className='left'>
              {!isSearch? <></> : 
                <div onClick={clear} className='searchclear-mobile' title={exibirMensagem("limpaaPesquisa")}>
                  <SvgIcon className='closeSvgIcon' icon={xIcon} />
                </div>
              }
          </div>
          <div className='left' onClick={SearchCmd}>
            <img className='sendicon-mobile' src={SendIcon} alt="search" />
          </div>
        </div>
      :
        <></>
      :
      <div className='searchbox'>
        <div className='left'>
          <Input placeholder={exibirMensagem("digiteTextoParaPesquisar")} title={exibirMensagem("pressioneEnterParaPesquisar")} className='searchinput' value={searchTextTmp} onKeyUp={handleKeyUp} 
                 onChange={(e) => {setSearchTextTmp(e.value);}} />
        </div>
        <div className='left'>
          {!isSearch? <></> : 
          <div onClick={clear} className='searchclear' title={exibirMensagem("limpaaPesquisa")}>
            <SvgIcon className='closeSvgIcon' icon={xIcon} />
          </div>}
          <Button title={exibirMensagem("cliqueParaPesquisar")} onClick={SearchCmd} className='searchbutton'>{exibirMensagem("pesquisarPontos")}</Button>
        </div>        
      </div>          
    }
    </>
    );
  };

  export default SearchBox;