import { enMessages } from "./en-US";
import { ptMessages } from "./pt-Br";

const browserLanguage = navigator.language;
const messages: { [key: string]: string } = browserLanguage.startsWith('pt') ? ptMessages : enMessages;

export function exibirMensagem(key: string) {
    try {
        var result = messages[key];
        if (result === undefined) {
            console.log(key, "not found");
            return key;
        }
        return result;
    }
    catch (e) {
        console.log(key, e);
        return key;
    }
}