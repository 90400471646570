import axios from 'axios';
import { SearchBaseModel, SearchModel } from '../models/searchModel';
import { getApiUrlSearch, getShowLogs } from '../services/appSettings';

const baseUrl = getApiUrlSearch();

export const searchApi = async (library: string, textSearch : string) : Promise<SearchModel> => {
 
  if (!baseUrl) {
    console.error('Environment variable REACT_APP_API_URL_SEARCH is not set');
    return {status: '', pagesCount: 0, matchsCount: 0, matchs: []};
  }

  const url = `${baseUrl}/${library}/${textSearch}?matchslist=2`;

  if ( getShowLogs() === 'on') {
    console.log('Search api:: url: ' + url, 'textSearch:' + textSearch);
  }

  return axios.get(url)    
      .then(response => {                  
        var rowData = response.data as SearchBaseModel;    
        return rowData.data;
      })
      .catch(error => {                 
        if ( getShowLogs() === 'on') {
          console.log('Search api: ' + error, 'textSearch:' + textSearch);
        }

        if (error.response && error.response.status === 422) {
          return {status: '422', pagesCount: 0, matchsCount: 0, matchs: []};  
        }
        return {status: 'ERROR', pagesCount: 0, matchsCount: 0, matchs: []};
      }          
  );
};